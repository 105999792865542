<template>
    <h1>PRİCİNG</h1>
</template>


<script>
export default {
    
}
</script>

<style scoped>

</style>